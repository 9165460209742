
import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './B11U1L1.scss';

export default class B11U1L1 extends Component {

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

 
    return (

      // <div class="contenedor"> 
 
<Grid style={{ backgroundColor: '#101010'}}>

  <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
    <>
    <h2 className="lesson1title"><br></br>Lesson 1</h2>
    <Slider {...settings}>
    <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU2ES%2F1a930e477bd472ca1ebf711d77a91b4a-0.jpg?alt=media&token=6ad26fdb-edb5-4388-a026-b4f057ffc1bc'} alt="boohoo" className="img-responsive"/>
          <br/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU2ES%2F1a930e477bd472ca1ebf711d77a91b4a-1.jpg?alt=media&token=1fce807b-0f82-4ff0-9198-fb90665a7893'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU2ES%2F1a930e477bd472ca1ebf711d77a91b4a-2.jpg?alt=media&token=d090982c-f8c3-4c85-86ec-7283bf53e6e1'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU2ES%2F1a930e477bd472ca1ebf711d77a91b4a-3.jpg?alt=media&token=31f4dc0c-d4f4-4880-a2e1-685f61fcdfe1'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU2ES%2F1a930e477bd472ca1ebf711d77a91b4a-4.jpg?alt=media&token=173d3f27-713d-4d74-b35b-1c324f7d7e8f'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU2ES%2F1a930e477bd472ca1ebf711d77a91b4a-5.jpg?alt=media&token=99caaf07-0511-4f68-aa21-6a679cf122b7'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU2ES%2F1a930e477bd472ca1ebf711d77a91b4a-6.jpg?alt=media&token=3b3f9cda-7331-4d72-9805-2ac382a6f0d1'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU2ES%2F1a930e477bd472ca1ebf711d77a91b4a-7.jpg?alt=media&token=155819bb-6376-41ff-934c-d75dbb140e94'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU2ES%2F1a930e477bd472ca1ebf711d77a91b4a-8.jpg?alt=media&token=3c8f93ed-6bde-4baf-9156-d622acd91a0a'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU2ES%2F1a930e477bd472ca1ebf711d77a91b4a-9.jpg?alt=media&token=645fe5dc-3f87-44a7-bd85-eac4410806ba'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU2ES%2F1a930e477bd472ca1ebf711d77a91b4a-10.jpg?alt=media&token=30ed1a7a-61a8-40e5-993a-10cdcc762ce6'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU2ES%2F1a930e477bd472ca1ebf711d77a91b4a-11.jpg?alt=media&token=2916e840-dbb8-4d1e-af74-93f5ea8e730d'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU2ES%2F1a930e477bd472ca1ebf711d77a91b4a-12.jpg?alt=media&token=1c33942a-bc13-4558-aaad-1472a80eaf7a'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU2ES%2F1a930e477bd472ca1ebf711d77a91b4a-13.jpg?alt=media&token=a01bdef8-0b16-49c0-9b4e-3d0d0f3950a1'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU2ES%2F1a930e477bd472ca1ebf711d77a91b4a-14.jpg?alt=media&token=c1254a2f-5759-4cbb-af72-043295173f92'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU2ES%2F1a930e477bd472ca1ebf711d77a91b4a-15.jpg?alt=media&token=f51ab7b4-9733-431d-b567-8b382f5d99d9'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU2ES%2F1a930e477bd472ca1ebf711d77a91b4a-16.jpg?alt=media&token=14eb5b48-564f-4ded-8e7b-b1ebebe40c3c'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU2ES%2F1a930e477bd472ca1ebf711d77a91b4a-17.jpg?alt=media&token=6b709bc2-e0de-48c2-beab-ce11ea6b838d'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU2ES%2F1a930e477bd472ca1ebf711d77a91b4a-18.jpg?alt=media&token=2ea17374-6af3-4031-8f98-6cb068a8b7d1'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU2ES%2F1a930e477bd472ca1ebf711d77a91b4a-19.jpg?alt=media&token=e2788b59-fc9b-4870-8951-a4a892e626d8'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU2ES%2F1a930e477bd472ca1ebf711d77a91b4a-20.jpg?alt=media&token=0c0cd06b-4bb0-4bf9-ae4a-33f954fbf05c'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU2ES%2F1a930e477bd472ca1ebf711d77a91b4a-21.jpg?alt=media&token=6643d449-c106-4d13-871e-93a14bf63987'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU2ES%2F1a930e477bd472ca1ebf711d77a91b4a-22.jpg?alt=media&token=4f03b4fa-3991-4f2b-8798-2692ff98f20c'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU2ES%2F1a930e477bd472ca1ebf711d77a91b4a-23.jpg?alt=media&token=e528e4fb-bfcd-40e6-994b-394d52b8a7ad'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU2ES%2F1a930e477bd472ca1ebf711d77a91b4a-24.jpg?alt=media&token=150b6060-8af2-4b5b-b2e5-60ee75d4605a'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU2ES%2F1a930e477bd472ca1ebf711d77a91b4a-25.jpg?alt=media&token=ceaf6fc2-e852-4d61-a409-f11798d712bd'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU2ES%2F1a930e477bd472ca1ebf711d77a91b4a-26.jpg?alt=media&token=9787b88f-71b3-4aac-83d8-c1f794dae8e1'} alt="boohoo" className="img-responsive"/>
          </div>
         

        </Slider>
      </>
      <div class="center"> 
      <Button href='https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU2ES%2FDIVU2ESMAT.pdf?alt=media&token=e0484f49-59c0-476b-b4ec-cf43228808d6' download className="btn-download-uno">Download resources</Button>
      </div>
  </Grid.Column>
  
  <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>
</Grid>
 
      // </div>
    );
  }
}
